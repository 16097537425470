<template>
    <pagination title="Employment" :entities="user" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :checkSize="false" :withEditIcon="updateAction.fields.filter(field => (field.name === 'role_ids' || field.name === 'project_ids' || field.name === 'self_employed' || field.name === 'contract_start_date' || field.name === 'holidays_per_year' || field.name === 'exclude_from_address_book' || field.name === 'company_type' || field.name === 'company_name' || field.name === 'company_address' || field.name === 'site_times_apply') && field.type !== 'hidden').size > 0" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')">
        <template v-slot:items>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Roles
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="roles"
                        :action="editing? updateAction: null"
                        fieldId="role_ids"
                        :show-label="false"
                        v-model="updateActionData['role_ids']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr v-if="user.properties.get('projects') || (updateAction.fields.filter(field => field.name === 'project_ids').first() && updateAction.fields.filter(field => field.name === 'project_ids').first().type !== 'hidden')">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Projects
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="project_ids"
                        :action="editing? updateAction: null"
                        fieldId="project_ids"
                        :show-label="false"
                        v-model="updateActionData['project_ids']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Self Employed
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                                       :properties="user.properties"
                                       property-key="self_employed"
                                       :action="editing? updateAction: null"
                                       fieldId="self_employed"
                                       :show-label="false"
                                       v-model="updateActionData['self_employed']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Contract Start Date
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="contract_start_date"
                        :action="editing? updateAction: null"
                        fieldId="contract_start_date"
                        :show-label="false"
                        v-model="updateActionData['contract_start_date']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Holidays Per Year
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="holidays_per_year"
                        :action="editing? updateAction: null"
                        fieldId="holidays_per_year"
                        :show-label="false"
                        v-model="updateActionData['holidays_per_year']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Exclude From Address Book
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="exclude_from_address_book"
                        :action="editing? updateAction: null"
                        fieldId="exclude_from_address_book"
                        :show-label="false"
                        v-model="updateActionData['exclude_from_address_book']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr v-if="user.properties.get('company_type')">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Company Type
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="company_type"
                        :action="editing? updateAction: null"
                        fieldId="company_type"
                        :show-label="false"
                        v-model="updateActionData['company_type']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr v-if="user.properties.get('company_name')">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Company Name
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="company_name"
                        :action="editing? updateAction: null"
                        fieldId="company_name"
                        :show-label="false"
                        v-model="updateActionData['company_name']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr v-if="user.properties.get('company_address')">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Company Address
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="company_address"
                        :action="editing? updateAction: null"
                        fieldId="company_address"
                        :show-label="false"
                        v-model="updateActionData['company_address']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Department
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="department"
                        :action="editing? updateAction: null"
                        fieldId="department"
                        :show-label="false"
                        v-model="updateActionData['department']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Site Times Apply
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="site_times_apply"
                        :action="editing? updateAction: null"
                        fieldId="site_times_apply"
                        :show-label="false"
                        v-model="updateActionData['site_times_apply']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>

        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";

export default {
  name: 'HrPersonEmploymentPagination',
    components: {PropertyOrField, Loading, Pagination},
  props: {
    user: {},
      collapsable:{
        type: Boolean,
          default: false,
      },
      open:{
        type: Boolean,
          default: false
      },
      editing: {
        type: Boolean,
          default: false
      },
      updateAction:{
        type: Object,
          default: null
      },
    updateActionData:{
        type: Object,
        default: null
    }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
