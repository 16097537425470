<template>
  <div class="py-4 px-4 sm:px-6 rounded-t-lg bg-v3-gray-50 bg-opacity-10 hover:bg-opacity-20 dark:bg-v3-gray-800 dark:text-v3-gray-200 rounded-b-lg cursor-pointer" :class="{[activeClass]: isActive}" @click="$emit('click');">
    <span>
        <span>
            <slot name="title">
                Details
            </slot>
        </span>
    </span>

  </div>
</template>
<script>
export default {
  name: 'headerLink',
    props:{
      isActive:{
          type: Boolean,
          default: false
      },
        activeClass:{
          type: String,
            default: 'bg-opacity-20'
        }
    },
    methods:{
    }
}
</script>
